var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layer-seminar-guide" }, [
    _c("div", { staticClass: "seminar-guide-wrap" }, [
      _c("button", { staticClass: "btn-close", on: { click: _vm.closeGuide } }),
      _vm._m(0),
      _vm._m(1),
      _c("div", { staticClass: "checkbox-white-wrap" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.again,
              expression: "again",
            },
          ],
          attrs: { id: "againView", type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.again)
              ? _vm._i(_vm.again, null) > -1
              : _vm.again,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.again,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.again = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.again = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.again = $$c
              }
            },
          },
        }),
        _c("label", { attrs: { for: "againView" } }, [_vm._v("다시보지않기")]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "guide-img" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/contents/web-seminar-guide.png"),
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "txt-area" }, [
      _c("p", { staticClass: "title" }, [
        _vm._v(
          " 닥터인포에서 여러 제약회사의 웹 세미나를 한 눈에 확인하세요! "
        ),
      ]),
      _c("div", { staticClass: "round-box" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v("제약 웹 세미나 이렇게 이용하실 수 있어요!"),
        ]),
        _c("ul", [
          _c("li", [
            _vm._v(
              "여러 제약회사에서 진행하는 웹 세미나 일정을 한 눈에 확인하고, 사전 예약과 시청이 가능합니다."
            ),
          ]),
          _c("li", [
            _vm._v(
              "캘린더에서 사전 예약을 누르면 해당 제약회사 사이트로 이동하며, 최초 1회 통합 회원 인증 후에는 별도 로그인 없이 웹 세미나를 자유롭게 이용할 수 있습니다."
            ),
          ]),
          _c("li", [
            _vm._v(
              "사전 예약한 세미나는 MY 세미나에서 확인할 수 있고, 웹 세미나 시청 완료 시 닥터인포 포인트를 받을 수 있습니다."
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }