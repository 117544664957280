<template>
  <div class="layer-seminar-guide">
    <div class="seminar-guide-wrap">
      <button
        class="btn-close"
        @click="closeGuide"
      />
      <p class="guide-img">
        <img src="@/assets/images/contents/web-seminar-guide.png">
      </p>
      <div class="txt-area">
        <p class="title">
          닥터인포에서 여러 제약회사의 웹 세미나를 한 눈에 확인하세요!
        </p>
        <div class="round-box">
          <p class="title">제약 웹 세미나
            이렇게 이용하실 수 있어요!</p>
          <ul>
            <li>여러 제약회사에서 진행하는 웹 세미나 일정을 한 눈에 확인하고, 사전 예약과 시청이 가능합니다.</li>
            <li>캘린더에서 사전 예약을 누르면 해당 제약회사 사이트로 이동하며, 최초 1회 통합 회원 인증 후에는 별도 로그인 없이 웹 세미나를 자유롭게 이용할 수 있습니다.</li>
            <li>사전 예약한 세미나는 MY 세미나에서 확인할 수 있고, 웹 세미나 시청 완료 시 닥터인포 포인트를 받을 수 있습니다.</li>
          </ul>
        </div>
      </div>

      <div class="checkbox-white-wrap">
        <input
          id="againView"
          v-model="again"
          type="checkbox"
        >
        <label for="againView">다시보지않기</label>
      </div>
    </div>
  </div>
</template>

<script>
import { setPopupOption } from '@/common/popup/service'

export default {
  data() {
    return {
      again: false
    }
  },
  methods: {
    closeGuide() {
      if (this.again) {
        const popup = JSON.parse(localStorage.getItem('popup'))

        popup.clientGuide = true

        setPopupOption(popup)
      }

      this.$parent.guidePopup = false
    }
  }
}
</script>
