<template>
  <div data-label="제약 웹 세미나">
    <div class="web-seminar-wrap">
      <div class="calendar-info-top">
        <div class="left-col">
          <div class="month-wrap">
            <button
              v-if="!thisMonth"
              class="btn-month-prev"
              @click="prevMonth()"
            >
              이전 월
            </button>
            <p class="now-month">
              {{ query.year }}년 {{ query.month + 1 }}월
            </p>
            <button
              class="btn-month-next"
              @click="nextMonth()"
            >
              다음 월
            </button>
          </div>
        </div>

        <div class="right-col">
          <div class="label-wrap">
            <label class="label-seminar">MY 세미나에 등록된 세미나</label>
          </div>
          <div class="select-wrap">
            <select v-model="query.viewType">
              <option value="1">
                전체보기
              </option>
              <option value="2">
                MY 세미나에 등록된 세미나만 보기
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="calendar-area">
        <table>
          <colgroup>
            <col style="width: auto">
            <col style="width: 14.28%">
            <col style="width: 14.28%">
            <col style="width: 14.28%">
            <col style="width: 14.28%">
            <col style="width: 14.28%">
            <col style="width: 14.28%">
          </colgroup>
          <thead>
            <tr>
              <th>일</th>
              <th>월</th>
              <th>화</th>
              <th>수</th>
              <th>목</th>
              <th>금</th>
              <th>토</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(row, index) in data.rows"
              :key="row"
            >
              <CalendarDay
                v-for="day in data.days.slice(index * 7, index * 7 + 7)"
                :key="`${day.key}_${(day.schedules ? day.schedules.filter(e => e.isReserved).length : 0)}`"
                :day="day"
                :view-type="query.viewType"
                @openModal="openModal"
              />
            </tr>
          </tbody>
        </table>
      </div>

      <ModalClient
        v-if="modalClient.isShow"
        :id="modalClient.id"
        :seminar="modalClient.seminar"
        :call-type="callType"
        @closeModal="closeModal"
        @reloadModal="reloadModal"
        @reloadDays="reloadDays"
      />

      <client-seminar-guide v-if="guidePopup" />
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'

import { mapGetters } from 'vuex'
import { setStatistics } from '@/common/statistics/service'
import CalendarDay from '../calendar/Day.vue'
import ModalClient from '../calendar/ModalClient.vue'
import ClientSeminarGuide from '../guide/ClientSeminarGuide.vue'

export default {
  components: {
    CalendarDay,
    ModalClient,
    ClientSeminarGuide,
  },
  data() {
    return {
      init: {
        today: dayjs(),
      },
      query: {
        year: dayjs().year(),
        month: dayjs().month(),
        viewType: '1'
      },
      data: {
        days: [],
        rows: 0,
      },
      modalClient: {
        id: null,
        seminar: null,
        isShow: false,
      },
      modalKma: {
        id: null,
        seminar: null,
        isShow: false,
      },
      callType: 'client',
      guidePopup: false,
    }
  },
  computed: {
    thisMonth() {
      return this.init.today.year() === this.query.year && this.init.today.month() === this.query.month
    },
    ...mapGetters({
      userName: 'account/getName',
      popupSetting: 'util/getPopupSetting',
    }),
  },
  watch: {
    userName() {
      this.closeModal()
      this.fetchSeminars(this.query.year, this.query.month)
    },
    // popupSetting() {
    //   this.getPopupOption()
    // }
  },
  created() {
    // Main에서 DashBoard 통해 넘어 왔을 시 상세 Open
    if (this.$route.params.id) {
      this.openModal(this.$route.params)
    }
    // 호출 url 파라미터가 있는 경우 상세 Open (모아보기 배너 클릭)
    if (this.$route.query.id) {
      // 통계는 모아보기에서 넘어올 때 app에서 축적하므로 생략
      this.fetchClientSeminar(this.$route.query.id)
    }

    this.fetchSeminars(this.query.year, this.query.month)
  },
  mounted() {
    // this.getPopupOption()
  },
  methods: {
    // getPopupOption() {
    //   if (this.popupSetting) {
    //     if (JSON.parse(localStorage.getItem('popup')).clientGuide === false) this.guidePopup = true
    //   }
    // },
    nextMonth() {
      let next = dayjs().year(this.query.year).month(this.query.month).date(1)

      next = next.add(1, 'month')

      this.query = {
        year: next.year(),
        month: next.month(),
        viewType: this.query.viewType,
      }

      this.fetchSeminars(this.query.year, this.query.month)
    },
    prevMonth() {
      let prev = dayjs().year(this.query.year).month(this.query.month).date(1)

      prev = prev.subtract(1, 'month')

      this.query = {
        year: prev.year(),
        month: prev.month(),
        viewType: this.query.viewType,
      }

      this.fetchSeminars(this.query.year, this.query.month)
    },
    fetchSeminars(year, month) {
      axios.get(`/fu/webseminar/client/${year}/${month + 1}`)
        .then(rs => {
          this.data = {
            days: rs.data,
            rows: rs.data.length / 7,
          }
        })
        .catch(() => {
          this.$parent.showAlertDialog('세미나 목록 조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    fetchClientSeminar(webSeminarId) {
      axios.get(`/fu/webseminar/client/${webSeminarId}`)
        .then(rs => {
          this.modalClient = {
            id: webSeminarId,
            seminar: rs.data,
            isShow: true,
          }
        })
        .catch(() => {
          this.$parent.showAlertDialog('세미나 상세내용 조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    openModal(seminar) {
      setStatistics(seminar.entry === null ? 119 : seminar.entry, seminar.id)

      this.fetchClientSeminar(seminar.id)
    },
    closeModal() {
      this.modalClient = {
        id: null,
        seminar: null,
        isShow: false,
      }
    },
    reloadModal() {
      this.fetchClientSeminar(this.modalClient.id)
    },
    reloadDays(day, id, reserve) {
      const dayIndex = this.data.days.findIndex(e => e.key.split('_')[0] === day)
      const scheduleIndex = this.data.days[dayIndex].schedules.findIndex(e => e.id === id)

      this.data.days[dayIndex].schedules[scheduleIndex].isReserved = reserve
      this.data.days[dayIndex].key = `${dayjs(this.data.days[dayIndex].schedules[scheduleIndex].startDate).format('YYYYMMDD')}_${this.data.days[dayIndex].schedules.filter(e => e.isReserved).length}`

      // this.fetchSeminars(this.query.year, this.query.month)
    },
  },
}
</script>
